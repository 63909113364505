//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

/* eslint-disable max-len */
import React from 'react';

import I18n from 'i18next';

import BackButton             from '@connected/BackButton';
import Url                    from '@helper/Url';
import Button                 from '@stateless/atomic/Button';
import ClipspotsContainer     from '@stateless/atomic/ClipspotsContainer';
import ClipspotsContainerSize from '@stateless/atomic/ClipspotsContainer/ClipspotsContainerSize';
import HeadlineSubtitle       from '@stateless/atomic/HeadlineSubtitle';
import IconType               from '@stateless/atomic/Icon/IconType';
import Spacer                 from '@stateless/atomic/Spacer';

import styles from './styles.module.scss';

const DataPrivacyScreen = () => {
    const contactMailTarget = I18n.t('dataPrivacy.contactMailTarget');

    function onContactMailClick() {
        Url.openInNewTab(`mailto:${contactMailTarget}`);
    }

    return (
        <div className={styles.dataPrivacyContainer}>
            <BackButton
                backTextKey={'backToAboutUs'}
            />
            <HeadlineSubtitle
                subtitle={'Unsere Datenschutzrichtlinie.'}
                headline={'Datenschutzerklärung'}
            />
            <Spacer height={14} />
            <ClipspotsContainer size={ClipspotsContainerSize.full}>
                <p>
                    Wir nehmen den Schutz der Daten der Nutzer unserer Website und/oder unseres Mobile-App sehr ernst und verpflichten uns, die Informationen, die Nutzer uns in Verbindung mit der Nutzung unserer Website und/oder unseres Mobile-App zur Verfügung stellen, zu schützen. Des
                    Weiteren verpflichten wir uns, Ihre Daten gemäß anwendbarem Recht zu schützen und zu verwenden.
                </p>
                <Spacer height={7} />
                <p>
                    Diese Datenschutzrichtlinie erläutert unsere Praktiken in Bezug auf die Erfassung, Verwendung und Offenlegung Ihrer Daten durch die Nutzung unserer digitalen Assets, wenn Sie über Ihre Geräte auf die Dienste zugreifen.
                </p>
                <Spacer height={7} />
                <p>
                    Lesen Sie die Datenschutzrichtlinie bitte sorgfältig durch und stellen Sie sicher, dass Sie unsere Praktiken in Bezug auf Ihre Daten vollumfänglich verstehen, bevor Sie unsere Dienste verwenden. Wenn Sie diese Richtlinie gelesen, vollumfänglich verstanden haben und
                    nicht mit unserer Vorgehensweise einverstanden sind, müssen Sie die Nutzung unserer digitalen Assets und Dienste einstellen. Mit der Nutzung unserer Dienste erkennen Sie die Bedingungen dieser Datenschutzrichtlinie an. Die weitere Nutzung der Dienste stellt Ihre
                    Zustimmung zu dieser Datenschutzrichtlinie und allen Änderungen daran dar.
                </p>
                <Spacer height={7} />
                <strong>
                    In dieser Datenschutzrichtlinie erfahren Sie:
                </strong>
                <p>
                    • Wie wir Daten sammeln
                </p>
                <p>
                    • Welche Daten wir erfassen
                </p>
                <p>
                    • Warum wir diese Daten erfassen
                </p>
                <p>
                    • An wen wir die Daten weitergeben
                </p>
                <p>
                    • Wo die Daten gespeichert werden
                </p>
                <p>
                    • Wie lange die Daten vorgehalten werden
                </p>
                <p>
                    • Wie wir die Daten schützen
                </p>
                <p>
                    • Wie wir mit Minderjährigen umgehen
                </p>
                <p>
                    • Aktualisierungen oder Änderungen der Datenschutzrichtlinie
                </p>
            </ClipspotsContainer>
            <Spacer height={14} />
            <HeadlineSubtitle
                subtitle={'Nachstehend erhalten Sie einen Überblick über die Daten, die wir erfassen können:'}
                headline={'Welche Daten erfassen wir?'}
            />
            <Spacer height={14} />
            <ClipspotsContainer size={ClipspotsContainerSize.full}>
                <p>
                    • Nicht identifizierte und nicht identifizierbare Informationen, die Sie während des Registrierungsprozesses bereitstellen oder die über die Nutzung unserer Dienste gesammelt werden („nicht personenbezogene Daten“). Nicht personenbezogene Daten lassen keine
                    Rückschlüsse darauf zu, von wem sie erfasst wurden. Nicht personenbezogene Daten, die wir erfassen, bestehen hauptsächlich aus technischen und zusammengefassten Nutzungsinformationen.
                </p>
                <Spacer height={7} />
                <p>
                    • Individuell identifizierbare Informationen, d. h. all jene, über die man Sie identifizieren kann oder mit vertretbarem Aufwand identifizieren könnte („personenbezogene Daten“). Zu den personenbezogenen Daten, die wir über unsere Dienste erfassen, können
                    Informationen
                    gehören, die von Zeit zu Zeit angefordert werden, wie Namen, E-Mail-Adressen, Adressen, Telefonnummern, IP-Adressen und mehr. Wenn wir personenbezogene mit nicht personenbezogenen Daten kombinieren, werden diese, solange sie in Kombination vorliegen, von uns als
                    personenbezogene Daten behandelt.
                </p>
            </ClipspotsContainer>
            <Spacer height={14} />
            <HeadlineSubtitle
                subtitle={'Nachstehend sind die wichtigsten Methoden aufgeführt, die wir zur Sammlung von Daten verwenden:'}
                headline={'Wie sammeln wir Daten?'}
            />
            <Spacer height={14} />
            <ClipspotsContainer size={ClipspotsContainerSize.full}>
                <p>
                    • Wir erfassen Daten bei der Nutzung unserer Dienste. Wenn Sie also unsere digitalen Assets besuchen und Dienste nutzen, können wir die Nutzung, Sitzungen und die dazugehörigen Informationen sammeln, erfassen und speichern.
                </p>
                <Spacer height={7} />
                <p>
                    • Wir erfassen Daten, die Sie uns selbst zur Verfügung stellen, beispielsweise, wenn Sie über einen Kommunikationskanal direkt mit uns Kontakt aufnehmen (z. B. eine E-Mail mit einem Kommentar oder Feedback).
                </p>
                <Spacer height={7} />
                <p>
                    • Wir können, wie unten beschrieben, Daten aus Drittquellen erfassen.
                </p>
                <Spacer height={7} />
                <p>
                    • Wir erfassen Daten, die Sie uns zur Verfügung stellen, wenn Sie sich über einen Drittanbieter wie Facebook oder Google bei unseren Diensten anmelden.
                </p>
            </ClipspotsContainer>
            <Spacer height={14} />
            <HeadlineSubtitle
                subtitle={'Wir können Ihre Daten für folgende Zwecke verwenden:'}
                headline={'Warum erfassen wir diese Daten?'}
            />
            <Spacer height={14} />
            <ClipspotsContainer size={ClipspotsContainerSize.full}>
                <p>
                    • um unsere Dienste zur Verfügung zu stellen und zu betreiben;
                </p>
                <Spacer height={7} />
                <p>
                    • um unsere Dienste zu entwickeln, anzupassen und zu verbessern;
                </p>
                <Spacer height={7} />
                <p>
                    • um auf Ihr Feedback, Ihre Anfragen und Wünsche zu reagieren und Hilfe anzubieten;
                </p>
                <Spacer height={7} />
                <p>
                    • um Anforderungs- und Nutzungsmuster zu analysieren;
                </p>
                <Spacer height={7} />
                <p>
                    • für sonstige interne, statistische und Recherchezwecke;
                </p>
                <Spacer height={7} />
                <p>
                    • um unsere Möglichkeiten zur Datensicherheit und Betrugsprävention verbessern zu können;
                </p>
                <Spacer height={7} />
                <p>
                    • um Verstöße zu untersuchen und unsere Bedingungen und Richtlinien durchzusetzen sowie um dem anwendbaren Recht, den Vorschriften bzw. behördlichen Anordnungen zu entsprechen;
                </p>
                <Spacer height={7} />
                <p>
                    • um Ihnen Aktualisierungen, Nachrichten, Werbematerial und sonstige Informationen im Zusammenhang mit unseren Diensten zu übermitteln. Bei Werbe-E-Mails können Sie selbst entscheiden, ob Sie diese weiterhin erhalten möchten. Wenn nicht, klicken Sie einfach auf den
                    Abmeldelink in diesen E-Mails.
                </p>
            </ClipspotsContainer>
            <Spacer height={14} />
            <HeadlineSubtitle
                subtitle={'Wir können Ihre Daten an unsere Dienstleister weitergeben, um unsere Dienste zu betreiben (z. B. Speicherung von Daten über Hosting-Dienste Dritter, Bereitstellung technischer Unterstützung usw.).'}
                headline={'An wen geben wir diese Daten weiter?'}
            />
            <Spacer height={14} />
            <ClipspotsContainer size={ClipspotsContainerSize.full}>
                <p>
                    Wir können Ihre Daten auch unter folgenden Umständen offenlegen: (i) um rechtswidrige Aktivitäten oder sonstiges Fehlverhalten zu untersuchen, aufzudecken, zu verhindern oder dagegen vorzugehen; (ii) um unsere Rechte auf Verteidigung zu begründen oder auszuüben; (iii)
                    um unsere Rechte, unser Eigentum oder unsere persönliche Sicherheit sowie die Sicherheit unserer Nutzer oder der Öffentlichkeit zu schützen; (iv) im Falle eines Kontrollwechsels bei uns oder bei einem unserer verbundenen Unternehmen (im Wege einer Verschmelzung, des
                    Erwerbs oder Kaufs (im Wesentlichen) aller Vermögenswerte u. a.); (v) um Ihre Daten mittels befugter Drittanbieter zu erfassen, vorzuhalten und/oder zu verwalten (z. B. Cloud-Service-Anbieter), soweit dies für geschäftliche Zwecke angemessen ist; (vi) um mit
                    Drittanbietern gemeinsam an der Verbesserung Ihres Nutzererlebnisses zu arbeiten. Zur Vermeidung von Missverständnissen möchten wir darauf hinweisen, dass wir nicht personenbezogene Daten nach eigenem Ermessen an Dritte übermitteln bzw. weitergeben oder anderweitig
                    verwenden können.
                </p>
                <Spacer height={7} />
                <p>
                    Wenn Sie unsere Dienste besuchen oder darauf zugreifen, autorisieren wir Dritte dazu, Webbeacons, Cookies, Pixel Tags, Skripte sowie andere Technologien und Analysedienste („Tracking-Technologien“) einzusetzen. Diese Tracking-Technologien können es Dritten
                    ermöglichen, Ihre Daten automatisch zu erfassen, um das Navigationserlebnis auf unseren digitalen Assets zu verbessern, deren Performance zu optimieren und ein maßgeschneidertes Nutzererlebnis zu gewährleisten, sowie zu Zwecken der Sicherheit und der
                    Betrugsprävention.
                </p>
                <Spacer height={7} />
                <p>
                    Ohne Ihre Zustimmung werden wir Ihre E-Mail-Adresse oder andere personenbezogenen Daten nicht an Werbeunternehmen oder Werbenetzwerke weitergeben.
                </p>
            </ClipspotsContainer>
            <Spacer height={14} />
            <HeadlineSubtitle
                headline={'Wo speichern wir die Daten?'}
            />
            <Spacer height={14} />
            <ClipspotsContainer size={ClipspotsContainerSize.full}>
                <p>
                    Bitte beachten Sie, dass unsere Unternehmen sowie unsere vertrauenswürdigen Partner und Dienstanbieter auf der ganzen Welt ansässig sind. Zu den in dieser Datenschutzrichtlinie erläuterten Zwecke speichern und verarbeiten wir alle nicht personenbezogenen Daten, die
                    wir erfassen, in unterschiedlichen Rechtsordnungen.
                </p>
                <Spacer height={7} />
                <strong>
                    Personenbezogene Daten
                </strong>
                <Spacer height={7} />
                <p>
                    Personenbezogene Daten können in den Vereinigten Staaten, in Irland, Südkorea, Taiwan, Israel und soweit für die ordnungsgemäße Bereitstellung unserer Dienste und/oder gesetzlich vorgeschrieben (wie nachstehend weiter erläutert) in anderen Rechtsordnungen gepflegt,
                    verarbeitet und gespeichert werden.
                </p>
            </ClipspotsContainer>
            <Spacer height={14} />
            <HeadlineSubtitle
                headline={'Wie lange werden die Daten vorgehalten?'}
            />
            <Spacer height={14} />
            <ClipspotsContainer size={ClipspotsContainerSize.full}>
                <p>
                    Bitte beachten Sie, dass wir die erfassten Daten so lange aufbewahren, wie es für die Bereitstellung unserer Dienste, zur Einhaltung unserer gesetzlichen und vertraglichen Verpflichtungen gegenüber Ihnen, zur Beilegung von Streitigkeiten und zur Durchsetzung unserer
                    Vereinbarungen erforderlich ist.
                </p>
                <Spacer height={7} />
                <p>
                    Wir können unrichtige oder unvollständige Daten jederzeit nach eigenem Ermessen berichtigen, ergänzen oder löschen.
                </p>
            </ClipspotsContainer>
            <Spacer height={14} />
            <HeadlineSubtitle
                headline={'Wie schützen wir die Daten?'}
            />
            <Spacer height={14} />
            <ClipspotsContainer size={ClipspotsContainerSize.full}>
                <p>
                    Der Hosting-Dienst für unserer digitalen Assets stellt uns die Online-Plattform zu Verfügung, über die wir Ihnen unsere Dienste anbieten können. Ihre Daten können über die Datenspeicherung, Datenbanken und allgemeine Anwendungen unseres Hosting-Anbieters gespeichert
                    werden. Er speichert Ihre Daten auf sicheren Servern hinter einer Firewall und er bietet sicheren HTTPS-Zugriff auf die meisten Bereiche seiner Dienste.
                </p>
                <Spacer height={7} />
                <p>
                    Ungeachtet der von uns und unserem Hosting-Anbieter ergriffenen Maßnahmen und Bemühungen können und werden wir keinen absoluten Schutz und keine absolute Sicherheit der Daten garantieren, die Sie hochladen, veröffentlichen oder anderweitig an uns oder andere
                    weitergeben.
                </p>
                <Spacer height={7} />
                <p>
                    Aus diesem Grund möchten wir Sie bitten, sichere Passwörter festzulegen und uns oder anderen nach Möglichkeit keine vertraulichen Informationen zu übermitteln, deren Offenlegung Ihnen Ihrer Meinung nach erheblich bzw. nachhaltig schaden könnte. Da E-Mail und Instant
                    Messaging nicht als sichere Kommunikationsformen gelten, bitten wir Sie außerdem, keine vertraulichen Informationen über einen dieser Kommunikationskanäle weiterzugeben.
                </p>
            </ClipspotsContainer>
            <Spacer height={14} />
            <HeadlineSubtitle
                headline={'Wie gehen wir mit Minderjährigen um?'}
            />
            <Spacer height={14} />
            <ClipspotsContainer size={ClipspotsContainerSize.full}>
                <p>
                    Die Dienste sind nicht für Nutzer bestimmt, die noch nicht die gesetzliche Volljährigkeit erreicht haben. Wir werden wissentlich keine Daten von Kindern erfassen. Wenn Sie noch nicht volljährig sind, sollten Sie die Dienste nicht herunterladen oder nutzen und uns
                    keine Informationen zur Verfügung stellen.
                </p>
                <Spacer height={7} />
                <p>
                    Wir behalten uns das Recht vor, jederzeit einen Altersnachweis zu verlangen, damit wir überprüfen können, ob Minderjährige unsere Dienste nutzen. Für den Fall, dass wir Kenntnis davon erlangen, dass ein Minderjähriger unsere Dienste nutzt, können wir diesen Nutzern
                    den Zugang zu unseren Diensten untersagen und ihn sperren, und wir können alle bei uns gespeicherten Daten über diesen Nutzer löschen. Sollten Sie Grund zu der Annahme haben, dass ein Minderjähriger Daten an uns weitergegeben hat, nehmen Sie bitte, wie unten
                    erläutert, Kontakt zu uns auf.
                </p>
                <Spacer height={7} />
                <p>
                    Wir verwenden Ihre personenbezogenen Daten nur für die in der Datenschutzrichtlinie festgelegten Zwecke und nur, wenn wir davon überzeugt sind, dass:
                </p>
                <Spacer height={7} />
                <p>
                    • die Verwendung Ihrer personenbezogenen Daten erforderlich ist, um einen Vertrag zu erfüllen oder zu schließen (z. B. um Ihnen die Dienste selbst oder Kundenbetreuung bzw. technischen Support bereitzustellen);
                </p>
                <p>
                    • die Verwendung Ihrer personenbezogenen Daten notwendig ist, um entsprechenden rechtlichen oder behördlichen Verpflichtungen nachzukommen, oder
                </p>
                <p>
                    • die Verwendung Ihrer personenbezogenen Daten notwendig ist, um unsere berechtigten geschäftlichen Interessen zu unterstützen (unter der Maßgabe, dass dies jederzeit in einer Weise erfolgt, die verhältnismäßig ist und Ihre Datenschutzrechte respektiert).
                </p>
                <Spacer height={7} />
                <p>
                    Als EU-Ansässiger können Sie:
                </p>
                <Spacer height={7} />
                <p>
                    • eine Bestätigung darüber verlangen, ob personenbezogene Daten verarbeitet werden, die Sie betreffen, oder nicht, und Zugriff auf Ihre gespeicherten personenbezogenen Daten sowie auf bestimmte Zusatzinformationen anfordern;
                </p>
                <p>
                    • den Erhalt von personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format verlangen;
                </p>
                <p>
                    • die Berichtigung lhrer personenbezogenen Daten verlangen, die bei uns gespeichert sind;
                </p>
                <p>
                    • die Löschung Ihrer personenbezogenen Daten verlangen;
                </p>
                <p>
                    • der Verarbeitung Ihrer personenbezogenen Daten durch uns widersprechen;
                </p>
                <p>
                    • die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten verlangen, oder
                </p>
                <p>
                    • eine Beschwerde bei einer Aufsichtsbehörde einreichen.
                </p>
                <Spacer height={7} />
                <p>
                    Bitte beachten Sie jedoch, dass diese Rechte nicht uneingeschränkt gültig sind und unseren eigenen berechtigten Interessen und regulatorischen Anforderungen unterliegen können. Wenn Sie allgemeine Fragen zu den von uns erfassten personenbezogenen Daten und deren
                    Verwendung haben, wenden Sie sich bitte an uns, wie unten angegeben.
                </p>
                <Spacer height={7} />
                <p>
                    Im Zuge der Bereitstellung der Dienste können wir Daten grenzüberschreitend an verbundene Unternehmen oder andere Dritte und aus Ihrem Land/Ihrer Rechtsordnung in andere Länder/Rechtsordnungen weltweit übertragen. Durch die Nutzung der Dienste stimmen Sie der
                    Übertragung Ihrer Daten außerhalb des EWR zu.
                </p>
                <p>
                    Wenn Sie im EWR ansässig sind, werden Ihre personenbezogenen Daten nur dann an Standorte außerhalb des EWR übertragen, wenn wir davon überzeugt sind, dass ein angemessenes oder vergleichbares Niveau zum Schutz personenbezogener Daten besteht. Wir werden geeignete
                    Schritte unternehmen, um sicherzustellen, dass wir über angemessene vertragliche Vereinbarungen mit unseren Drittparteien verfügen, um zu gewährleisten, dass entsprechende Sicherheitsvorkehrungen getroffen werden, so dass das Risiko einer unrechtmäßigen Nutzung,
                    Änderung, Löschung, eines Verlusts oder Diebstahls Ihrer personenbezogenen Daten minimiert wird und dass diese Drittparteien jederzeit in Übereinstimmung mit den geltenden Gesetzen handeln.
                </p>
            </ClipspotsContainer>
            <Spacer height={14} />
            <HeadlineSubtitle
                headline={'Aktualisierungen oder Änderungen der Datenschutzrichtlinie'}
            />
            <Spacer height={14} />
            <ClipspotsContainer size={ClipspotsContainerSize.full}>
                <p>
                    Wir können diese Datenschutzrichtlinie nach eigenem Ermessen von Zeit zu Zeit überarbeiten, die auf der Website veröffentlichte Version ist immer aktuell (siehe Angabe zum „Stand“). Wir bitten Sie, diese Datenschutzrichtlinie regelmäßig auf Änderungen zu überprüfen.
                    Bei wesentlichen Änderungen werden wir einen Hinweis dazu auf unserer Website veröffentlichen. Wenn Sie die Dienste nach erfolgter Benachrichtigung über Änderungen auf unsere Website weiter nutzen, gilt dies als Ihre Bestätigung und Zustimmung zu den Änderungen der
                    Datenschutzrichtlinie und Ihr Einverständnis an die Bedingungen dieser Änderungen gebunden zu sein.
                </p>
            </ClipspotsContainer>
            <Spacer height={14} />
            <HeadlineSubtitle
                headline={'Kontakt'}
            />
            <Spacer height={14} />
            <ClipspotsContainer size={ClipspotsContainerSize.full}>
                <p>
                    Wenn Sie allgemeine Fragen zu den Diensten oder den von uns über Sie erfassten Daten und deren Verwendung haben, kontaktieren Sie uns bitte unter:
                </p>
                <Spacer height={7} />
                <p>
                    Name: Christian Engl
                </p>
                <p>
                    Anschrift: Klausenburger Straße 9/1, D-88069 Tettnang - Bürgermoos 2
                </p>
                <Spacer height={7} />
                <Button
                    className={styles.contactButton}
                    iconRight={IconType.share}
                    onClick={onContactMailClick}
                    text={contactMailTarget}
                />
            </ClipspotsContainer>
            <Spacer height={14} />
            <HeadlineSubtitle
                headline={'AUSSCHLUSSKLAUSEL'}
            />
            <Spacer height={14} />
            <ClipspotsContainer size={ClipspotsContainerSize.full}>
                <p>
                    Die hier enthaltenen Informationen ersetzen keine Rechtsberatung und Sie sollten sich nicht allein darauf stützen. Spezifische Anforderungen in Bezug auf Rechtsbegriffe und Richtlinien können sich von Bundesstaat zu Bundesstaat und/oder von Rechtssystem zu
                    Rechtssystem unterscheiden. Wie in unseren Nutzungsbedingungen dargelegt, sind Sie dafür verantwortlich sicherzustellen, dass Ihre Dienste nach dem für Sie maßgeblichen Recht zulässig sind und Sie sich daran halten.
                </p>
                <Spacer height={7} />
                <p>
                    Um sicherzustellen, dass Sie Ihren gesetzlichen Verpflichtungen vollumfänglich entsprechen, empfehlen wir Ihnen ausdrücklich, sich professionell beraten zu lassen, um besser nachvollziehen zu können, welche Anforderungen für Sie speziell gelten.
                </p>
            </ClipspotsContainer>
        </div>
    );
};

export default DataPrivacyScreen;
/* eslint-enable max-len */
