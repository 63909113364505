//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';
import _          from 'lodash';

import Logo                from '@assets/images/logoWhite.png';
import PropTypes           from '@components/PropTypes';
import Routes              from '@constants/Routes';
import NavigationBarHelper from '@helper/NavigationBarHelper';
import Icon                from '@stateless/atomic/Icon';
import Link                from '@stateless/atomic/Link';
import NavigationBarType   from '@stateless/composed/NavigationBar/NavigationBarType';

import styles from './styles.module.scss';

const propTypes = {
    currentRoute:      PropTypes.string,
    extraEntries:      PropTypes.array,
    navigationEntries: PropTypes.arrayOf(PropTypes.navigationBarEntry),
    type:              PropTypes.oneOf(NavigationBarType),
};

const NavigationBar = ({
    currentRoute = null,
    navigationEntries = NavigationBarHelper.navigationBarEntries,
    extraEntries = [],
    type = NavigationBarType.bottom,
}) => {
    const isSide = type === NavigationBarType.side;

    function createNavigationBarStyles({ iconWidth, translateX, bottomBarStyles = {} }) {
        const iconStyle = {
            width:     iconWidth,
            transform: `translateX(${translateX}px)`,
        };

        if (!isSide) {
            Object.assign(iconStyle, {
                width:     bottomBarStyles.iconWidth,
                transform: `translateX(${bottomBarStyles.translateX}px)`,
            });
        }

        return iconStyle;
    }

    function renderNavigationEntry(entry, className) {
        const { title, icon, route } = entry;
        const isActive               = currentRoute.startsWith(route);
        const renderTitle            = (
            isSide ?
                title :
                null
        );
        const iconStyle              = createNavigationBarStyles(entry);

        return (
            <Link
                key={title}
                className={classNames(
                    styles[`${type}NavigationBarEntry`],
                    {
                        [styles[`${type}NavigationBarEntryActive`]]: isActive,
                    },
                    className,
                )}
                to={route}
            >
                <Icon
                    className={styles[`${type}NavigationBarIcon`]}
                    iconType={icon}
                    iconStyle={iconStyle}
                />
                {renderTitle}
            </Link>
        );
    }

    function renderNavigationEntries() {
        return (
            _.map(navigationEntries, renderNavigationEntry)
        );
    }

    function renderExtraEntries() {
        if (extraEntries.length > 0) {
            return (
                <div className={styles.navigationBarExtraEntries}>
                    {_.map(extraEntries, renderNavigationEntry)}
                </div>
            );
        }

        return null;
    }

    function renderUpperLeftLogo() {
        if (isSide) {
            return (
                <Link
                    className={styles.upperLeftLogoContainer}
                    to={Routes.videoclips}
                >
                    <img
                        alt={I18n.t('pageContainer.upperLeftLogoAlt')}
                        className={styles.logo}
                        src={Logo}
                    />
                </Link>
            );
        }

        return null;
    }

    return (
        <div className={styles[`${type}NavigationBar`]}>
            {renderUpperLeftLogo()}
            <div className={styles[`${type}NavigationBarEntries`]}>
                {renderNavigationEntries()}
                {renderExtraEntries()}
            </div>
        </div>
    );
};

NavigationBar.propTypes = propTypes;

export default NavigationBar;
