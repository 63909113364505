//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useRef }    from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import PropTypes           from '@components/PropTypes';
import EventListenerEffect from '@helper/EventListener';
import VideoHelper         from '@helper/VideoHelper';
import Icon                from '@stateless/atomic/Icon';
import IconType            from '@stateless/atomic/Icon/IconType';
import ProgressBar         from '@stateless/atomic/ProgressBar';

import styles from './styles.module.scss';

const propTypes = {
    children: PropTypes.node,
    headline: PropTypes.string.isRequired,
    onClose:  PropTypes.func.isRequired,
    videoSrc: PropTypes.string.isRequired,
};

const VideoOverlay = ({ headline, videoSrc, children, onClose }) => {
    const videoRef                = useRef(null);
    const [isPlaying, setPlaying] = useState(true);
    const [progress, setProgress] = useState(0);
    const playPauseIconType       = (
        isPlaying
            ? IconType.pause
            : IconType.playButton
    );

    useEffect(() => {
        const videoElement   = videoRef.current;
        const updateProgress = () => {
            setProgress((
                videoElement.currentTime / videoElement.duration
            ) * 100);
        };
        const onWaiting      = () => {
            setPlaying(false);
        };
        const onPlaying      = () => {
            setPlaying(true);
        };
        const handleKeyDown  = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        if (!VideoHelper.isVideoElementPlaying(videoElement)) {
            videoElement.play();
        }

        return EventListenerEffect.subscribeAndRemove([
            EventListenerEffect.eventWithCallback('timeupdate', updateProgress, videoElement),
            EventListenerEffect.eventWithCallback('waiting', onWaiting, videoElement),
            EventListenerEffect.eventWithCallback('playing', onPlaying, videoElement),
            EventListenerEffect.eventWithCallback('keydown', handleKeyDown, window),
        ]);
    }, []);

    const handlePlayPauseClick = (event) => {
        event.stopPropagation();

        const videoElement = videoRef.current;

        if (videoElement.paused) {
            videoElement.play();
            setPlaying(true);
        } else {
            videoElement.pause();
            setPlaying(false);
        }
    };

    function handleCloseClick(event) {
        event.stopPropagation();
        onClose();
    }

    function onVideoOverlayClick(event) {
        event.stopPropagation();
    }

    function onVideoContentClick(event) {
        event.stopPropagation();
        handlePlayPauseClick(event);
    }

    function onProgressChange(newProgress) {
        const videoElement = videoRef.current;
        const duration     = videoElement?.duration;
        const newTime      = (
            newProgress / 100
        ) * duration;

        if (!_.isNaN(newTime)) {
            videoElement.currentTime = newTime;

            setProgress(newProgress);
        }
    }

    return (
        <div
            className={styles.videoOverlay}
            onClick={onVideoOverlayClick}
        >
            <div
                className={styles.contentOverlay}
                onClick={onVideoContentClick}
            >
                <video
                    ref={videoRef}
                    className={styles.videoBackground}
                    loop
                    autoPlay
                >
                    <source
                        src={videoSrc}
                        type="video/mp4"
                    />
                    {I18n.t('videoNotSupported')}
                </video>
                <span className={styles.headline}>
                    {headline}
                </span>
                <Icon
                    className={styles.closeButton}
                    iconType={IconType.cross}
                    onClick={handleCloseClick}
                />
                <Icon
                    className={styles.playPauseButton}
                    iconType={playPauseIconType}
                    onClick={handlePlayPauseClick}
                />
                <ProgressBar
                    onProgressChange={onProgressChange}
                    progress={progress}
                />
                {children}
            </div>
        </div>
    );
};

VideoOverlay.propTypes = propTypes;

export default VideoOverlay;
