//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Environment from '@helper/Environment';

import ApiUrls from './ApiUrls';

const PRODUCTION_BACKEND_URL = 'https://clipspots-app.de';

const STAGING_BACKEND_URL = 'https://staging.clipspots-app.de';

const LOCAL_BACKEND_URL = 'https://clipspots-backend.test';

export default class Api {
    static getBackendUrl() {
        const url                       = window.location.href;
        const localBackendUrlIndicators = [
            '.test',
            'localhost',
            '127.0.0.1',
            'ngrok.io',
            'loca.lt',
        ];

        for (const localBackendUrlIndicator of localBackendUrlIndicators) {
            if (url.indexOf(localBackendUrlIndicator) > -1) {
                return LOCAL_BACKEND_URL;
            }
        }

        if (url.indexOf('staging.') > -1) {
            return STAGING_BACKEND_URL;
        }

        return PRODUCTION_BACKEND_URL;
    }

    /**
     * @returns {boolean}
     */
    static isStaging() {
        return (
            this.getBackendUrl() === STAGING_BACKEND_URL
        );
    }

    /**
     * @param host
     */
    static checkBackendUrl(host) {
        const isTestEnvironment = Environment.isTest();
        const showWarning       = !isTestEnvironment;

        if (
            host.endsWith('/') &&
            showWarning
        ) {
            console.error('⚠️⚠️⚠️ Warning: Backend url should not end with a /', host);
        }

        if (
            host !== PRODUCTION_BACKEND_URL &&
            showWarning
        ) {
            console.warn('⚠️⚠️⚠️ Warning: App is not in production mode! ⚠️⚠️⚠️');
        }

        if (
            host.indexOf('https://') === -1 &&
            showWarning
        ) {
            console.error('⚠️⚠️⚠️ Warning: Backend url is not secure ⚠️⚠️⚠️');
        }
    }

    /**
     * @returns {[]}
     */
    static getAllUrls() {
        const apis = [];

        Object.keys(ApiUrls).forEach((key) => {
            const url = ApiUrls[key];

            if (typeof url !== 'function') {
                apis.push(url);
            }
        });

        return apis;
    }
}
