//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }  from 'redux-saga/effects';
import { call } from 'redux-saga/effects';

import * as Api              from '@api/index';
import HydraHelper           from '@helper/Hydra';
import { DigitalAdsActions } from '@slices/digitalAds';

function* fetchDigitalAds() {
    const response = yield call(Api.context.digitalAds.fetchDigitalAds);

    if (response.ok) {
        const digitalAds = HydraHelper.getMembersFromResponse(response.data);

        yield put(DigitalAdsActions.fetchDigitalAdsSucceeded({
            digitalAds,
        }));
    } else {
        yield put(DigitalAdsActions.fetchDigitalAdsFailed());
    }
}

export default {
    fetchDigitalAds,
};
