//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';
import { useRef }    from 'react';
import { useState }  from 'react';

import _ from 'lodash';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    onProgressChange: PropTypes.func,
    progress:         PropTypes.number,
};

const ProgressBar = ({
    onProgressChange = _.noop,
    progress = 0,
}) => {
    const [isMouseDown, setIsMouseDown] = useState(false);
    const progressBarContainerRef       = useRef();
    const progressBarStyle              = {
        width: `${progress}%`,
    };

    function handleProgressChange(event) {
        const rect        = progressBarContainerRef.current.getBoundingClientRect();
        const x           = event.clientX - rect.left;
        const width       = rect.width;
        const newProgress = (
            x / width
        ) * 100;
        onProgressChange(newProgress);
    }

    function handleMouseDown(event) {
        setIsMouseDown(true);
        handleProgressChange(event);
    }

    function handleMouseUp() {
        setIsMouseDown(false);
    }

    function handleMouseMove(event) {
        if (isMouseDown) {
            handleProgressChange(event);
        }
    }

    useEffect(() => {
        function removeMouseMoveListener() {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        if (isMouseDown) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            removeMouseMoveListener();
        }

        return removeMouseMoveListener;
    }, [isMouseDown]);

    return (
        <div
            className={styles.progressBarContainer}
            ref={progressBarContainerRef}
        >
            <div
                className={styles.progressBar}
                style={progressBarStyle}
            >
            </div>
            <div
                className={styles.progressBarOverlay}
                onMouseDown={handleMouseDown}
            >
            </div>
        </div>
    );
};

ProgressBar.propTypes = propTypes;

export default ProgressBar;
