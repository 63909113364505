//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { Splide }      from '@splidejs/react-splide';
import { SplideSlide } from '@splidejs/react-splide';
import { SplideTrack } from '@splidejs/react-splide';
import classNames      from 'classnames';
import _               from 'lodash';

import PropTypes               from '@components/PropTypes';
import VideoType               from '@constants/VideoType';
import ClipspotsContainerSize  from '@stateless/atomic/ClipspotsContainer/ClipspotsContainerSize';
import Icon                    from '@stateless/atomic/Icon';
import IconType                from '@stateless/atomic/Icon/IconType';
import VideoClipspotsContainer from '@stateless/composed/VideoClipspotsContainer';

import styles from './styles.module.scss';

// eslint-disable-next-line rulesdir/format-import-linter
import '@splidejs/react-splide/css';

const propTypes = {
    videoType: PropTypes.videoType,
    videos:    PropTypes.array,
};

const VideoSlider = ({
    videos = [],
    videoType = VideoType.video,
}) => {
    function renderVideo(video) {
        return (
            <SplideSlide>
                <VideoClipspotsContainer
                    video={video}
                    size={ClipspotsContainerSize.medium}
                    videoType={videoType}
                />
            </SplideSlide>
        );
    }

    return (
        <Splide
            className={styles.splide}
            options={{
                autoWidth:  true,
                autoHeight: true,
                pagination: false,
                gap:        15,
                perPage:    2,
                focus:      0,
                perMove:    1,
            }}
            hasTrack={false}
        >
            <div className="splide__arrows">
                <Icon
                    className={classNames(
                        'splide__arrow',
                        'splide__arrow--prev',
                        styles.arrow,
                    )}
                    iconType={IconType.chevronRight}
                />
                <Icon
                    className={classNames(
                        'splide__arrow',
                        'splide__arrow--next',
                        styles.arrow,
                    )}
                    iconType={IconType.chevronRight}
                />
            </div>
            <SplideTrack>
                {_.map(
                    videos,
                    renderVideo,
                )}
            </SplideTrack>
        </Splide>
    );
};

VideoSlider.propTypes = propTypes;

export default VideoSlider;
