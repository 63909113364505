//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React, { useState } from 'react';

import _ from 'lodash';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    inputReference: PropTypes.object,
    isEditing:      PropTypes.bool,
    setEditing:     PropTypes.func,
    text:           PropTypes.string.isRequired,
};

const EditableText = ({
    inputReference = null,
    isEditing = false,
    setEditing = _.noop,
    text = null,
}) => {
    const [currentText, setCurrentText] = useState(text);

    function onClick() {
        setEditing(true);
    }

    function handleChange(event) {
        setCurrentText(event.target.value);
    }

    function renderText() {
        if (isEditing) {
            return (
                <textarea
                    autoFocus
                    ref={inputReference}
                    value={currentText}
                    onChange={handleChange}
                    className={styles.input}
                />
            );
        }

        return (
            <span className={styles.text}>
                {currentText}
            </span>
        );
    }

    return (
        <div
            className={styles.editableText}
            onClick={onClick}
        >
            {renderText()}
        </div>
    );
};

EditableText.propTypes = propTypes;

export default EditableText;
