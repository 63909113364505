//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import AppStoreLogo         from '@assets/images/appStore.svg';
import BackgroundLogo       from '@assets/images/backgroundLogo.webp';
import playStoreLogo        from '@assets/images/playStoreLogo.png';
import HeadlineSubtitle     from '@stateless/atomic/HeadlineSubtitle';
import HeadlineSubtitleType from '@stateless/atomic/HeadlineSubtitle/HeadlineSubtitleType';

import styles from './styles.module.scss';

const ScreenTooSmallOverlay = () => {
    return (
        <div
            aria-hidden={'true'}
            className={styles.screenTooSmallOverlay}
        >
            <div className={styles.screenTooSmallOverlayContent}>
                <img
                    alt={I18n.t('screenTooSmall.backgroundLogoAlt')}
                    className={styles.backgroundLogo}
                    src={BackgroundLogo}
                />
                <HeadlineSubtitle
                    type={HeadlineSubtitleType.pageSection}
                    headline={I18n.t('screenTooSmall.title')}
                />
                <p>
                    {I18n.t('screenTooSmall.tooSmallText')}
                </p>
                <p>
                    {I18n.t('screenTooSmall.downloadOurAppText')}
                </p>
                <div className={styles.storeLogos}>
                    <a
                        href={I18n.t('screenTooSmall.appleAppStoreLink')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <AppStoreLogo />
                    </a>
                    <a
                        href={I18n.t('screenTooSmall.googlePlayStoreLink')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={playStoreLogo}
                            alt={I18n.t('screenTooSmall.playStoreAlt')}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ScreenTooSmallOverlay;
