//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

class EventListenerEffect {
    static eventWithCallback(event, callback, element = undefined) {
        const eventWithCallback = {
            event,
            callback,
        };

        if (element !== undefined) {
            eventWithCallback.element = element;
        }

        return eventWithCallback;
    }

    static subscribeAndRemove(eventsAndCallbacks = [], baseElement = document) {
        const addOperation        = 'addEventListener';
        const removeOperation     = 'removeEventListener';
        const handleEventListener = (operation) => {
            for (const eventAndCallbacks of eventsAndCallbacks) {
                const { event, callback } = eventAndCallbacks;
                let resolvedElement       = baseElement;

                if (_.has(eventAndCallbacks, 'element')) {
                    resolvedElement = eventAndCallbacks.element;
                }

                if (
                    resolvedElement &&
                    _.isFunction(resolvedElement[operation])
                ) {
                    resolvedElement[operation](event, callback);
                }
            }
        };

        handleEventListener(addOperation);

        return () => {
            handleEventListener(removeOperation);
        };
    }
}

export default EventListenerEffect;
