//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Api from '@constants/Api';

class Url {
    static openInNewTab(url) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    static downloadBackendUrlFile(path) {
        return this.openInNewTab(`${path}/download`);
    }

    static backendImage(path) {
        return Api.getBackendUrl() + path;
    }
}

export default Url;
