//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useSelector } from 'react-redux';
import { Route }       from 'react-router';
import { Switch }      from 'react-router';
import { Redirect }    from 'react-router';

import Routes                from '@constants/Routes';
import AboutUs               from '@screens/AboutUs';
import BusinessCard          from '@screens/BusinessCard';
import DataPrivacyScreen     from '@screens/DataPrivacyScreen';
import DigitalAdDetail       from '@screens/DigitalAdDetail';
import DigitalAds            from '@screens/DigitalAds';
import Home                  from '@screens/Home';
import ImprintScreen         from '@screens/ImprintScreen';
import Login                 from '@screens/Login';
import LogoutScreen          from '@screens/LogoutScreen';
import NotFound              from '@screens/NotFound';
import ShortclipDetailScreen from '@screens/ShortclipDetailScreen';
import Shortclips            from '@screens/Shortclips';
import TutorialDetailScreen  from '@screens/TutorialDetailScreen';
import Tutorials             from '@screens/Tutorials';
import VideoDetailScreen     from '@screens/VideoDetailScreen';
import { selectToken }       from '@store/selectors/user';

const MainRouter = () => {
    const token = useSelector(selectToken);

    function renderLogin() {
        if (token) {
            return (
                <Redirect to={Routes.videoclips} />
            );
        }

        return (
            <Login />
        );
    }

    return (
        <Switch>
            <Route
                exact
                path={Routes.home}
            >
                <Redirect to={Routes.videoclips} />
            </Route>
            <Route
                exact
                path={Routes.login}
                component={Login}
            >
                {renderLogin()}
            </Route>
            <Route
                exact
                path={Routes.videoclips}
                component={Home}
            />
            <Route
                exact
                path={Routes.shortClips}
                component={Shortclips}
            />
            <Route
                exact
                path={Routes.digitalAds}
                component={DigitalAds}
            />
            <Route
                exact
                path={Routes.digitalAdDetail}
                component={DigitalAdDetail}
            />
            <Route
                exact
                path={Routes.businessCard}
                component={BusinessCard}
            />
            <Route
                exact
                path={Routes.aboutUs}
                component={AboutUs}
            />
            <Route
                exact
                path={Routes.dataPrivacy}
                component={DataPrivacyScreen}
            />
            <Route
                exact
                path={Routes.imprint}
                component={ImprintScreen}
            />
            <Route
                exact
                path={Routes.tutorials}
                component={Tutorials}
            />
            <Route
                exact
                path={Routes.tutorialDetail}
                component={TutorialDetailScreen}
            />
            <Route
                exact
                path={Routes.videoDetail}
                component={VideoDetailScreen}
            />
            <Route
                exact
                path={Routes.shortclipDetail}
                component={ShortclipDetailScreen}
            />
            <Route
                exact
                path={Routes.logout}
                component={LogoutScreen}
            />
            <Route component={NotFound} />
        </Switch>
    );
};

export default MainRouter;
