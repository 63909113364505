//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    digitalAds: [],
});

const digitalAdsSlice = createSlice({
    name:     'digitalAds',
    initialState,
    reducers: {
        fetchDigitalAds:          LoadingLevelHelper.increaseLoading((state, action) => {
        }),
        fetchDigitalAdsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                digitalAds: {
                    $set: action.payload.digitalAds,
                },
            });
        }),
        fetchDigitalAdsFailed:    LoadingLevelHelper.decreaseLoading((state, action) => {
        }),
    },
});

export const DigitalAdsActions = digitalAdsSlice.actions;

export const DigitalAdsReducer = digitalAdsSlice.reducer;

export default digitalAdsSlice;
