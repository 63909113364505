//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { useAlertBox }    from '@slices/alertBox';
import { useUser }        from '@slices/user';
import Button             from '@stateless/atomic/Button';
import ClipspotsContainer from '@stateless/atomic/ClipspotsContainer';
import Spacer             from '@stateless/atomic/Spacer';
import TextInput          from '@stateless/atomic/TextInput';
import TextInputType      from '@stateless/atomic/TextInput/TextInputType';

import styles from './styles.module.scss';

const Login = () => {
    const dispatch        = useDispatch();
    const userActions     = useUser(dispatch);
    const alertBoxActions = useAlertBox(dispatch);
    const username        = useSelector((state) => state.user.username);
    const password        = useSelector((state) => state.user.password);

    function onFormSubmit(event) {
        event.preventDefault();
        console.log('LoginScreen: loginFormSubmitted');
        alertBoxActions.clearAlerts();
        userActions.login();
    }

    function passwordChanged(event) {
        userActions.setPassword({
            password: event.target.value,
        });
    }

    function usernameChanged(event) {
        userActions.setUsername({
            username: event.target.value,
        });
    }

    return (
        <ClipspotsContainer
            className={styles.formWrapper}
            headline={I18n.t('login.welcome')}
            subtitle={I18n.t('login.enterData')}
        >
            <form
                className={styles.loginForm}
                onSubmit={onFormSubmit}
            >
                <div className={styles.inputContainer}>
                    <TextInput
                        type={TextInputType.email}
                        onChange={usernameChanged}
                        value={username}
                        placeholderText={I18n.t('login.emailAddress')}
                    />
                    <Spacer width={7} />
                    <TextInput
                        type={TextInputType.password}
                        onChange={passwordChanged}
                        value={password}
                        placeholderText={I18n.t('login.password')}
                    />
                </div>
                <Button
                    text={I18n.t('login.loginButton')}
                />
            </form>
        </ClipspotsContainer>
    );
};

export default Login;
