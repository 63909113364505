//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams }   from 'react-router';

import PropTypes               from '@components/PropTypes';
import BackButton              from '@connected/BackButton';
import { findVideoStackState } from '@constants/VideoType';
import VideoType               from '@constants/VideoType';
import Url                     from '@helper/Url';
import VideoHelper             from '@helper/VideoHelper';
import { useVideos }           from '@slices/videos';
import Button                  from '@stateless/atomic/Button';
import ButtonColor             from '@stateless/atomic/Button/ButtonColor';
import ButtonType              from '@stateless/atomic/Button/ButtonType';
import IconType                from '@stateless/atomic/Icon/IconType';
import PageTitle               from '@stateless/composed/PageTitle';
import VideoClipspotsContainer from '@stateless/composed/VideoClipspotsContainer';
import VideoSlider             from '@stateless/composed/VideoSlider';

import styles from './styles.module.scss';

const propTypes = {
    videoType: PropTypes.videoType,
};

const VideoDetailClipspotsContainer = ({
    videoType = null,
}) => {
    const params            = useParams();
    const dispatch          = useDispatch();
    const videoActions      = useVideos(dispatch);
    const videoId           = _.get(Object.values(params), '[0]');
    const videoStackState   = findVideoStackState(videoType);
    const videos            = useSelector((state) => _.get(state, videoStackState));
    const video             = VideoHelper.findVideoInStack(videos, videoId);
    const videoFilePaths    = {
        videoPath:        VideoHelper.getVideoPath(video),
        previewImagePath: VideoHelper.getVideoPreviewImagePath(video),
        videoTextPath:    VideoHelper.getVideoTextPath(video),
    };
    const translationPrefix = `${videoType}.detail`;
    const sliderVideoType   = videoType === VideoType.tutorial
        ? VideoType.video
        : videoType;
    const sliderVideos      = useSelector((state) => (
        sliderVideoType === videoType
            ? videos
            : _.get(state, findVideoStackState(sliderVideoType))
    ));

    function download(path) {
        if (path) {
            Url.downloadBackendUrlFile(path);
        }
    }

    function downloadAllVideos() {
        videoActions.downloadVideoZip({
            video,
        });
    }

    const downloadButton = (path) => () => {
        download(path);
    };

    function renderButton(path, translationSuffix, buttonColor = ButtonColor.white, downloadFunction = null) {
        return (
            (
                path || downloadFunction
            ) && (
                <Button
                    type={ButtonType.large}
                    onClick={downloadFunction || downloadButton(path)}
                    text={I18n.t(`${translationPrefix}.${translationSuffix}`)}
                    color={buttonColor}
                    iconRight={IconType.download}
                />
            )
        );
    }

    const renderAdditionalButtons = () => {
        const isNoTutorial = videoType !== VideoType.tutorial;

        return (
            <div className={styles.additionalButtonContainer}>
                {renderButton(videoFilePaths.videoPath, 'download')}
                {isNoTutorial && renderButton(videoFilePaths.previewImagePath, 'downloadPreview')}
                {isNoTutorial && renderButton(videoFilePaths.videoTextPath, 'downloadText')}
                {isNoTutorial && renderButton(null, 'downloadAll', ButtonColor.default, downloadAllVideos)}
            </div>
        );
    };

    return (
        <>
            <PageTitle title={video?.title} />
            <div className={styles.videoDetailContainer}>
                <BackButton videoType={videoType} />
                <div className={styles.videoClipspotsContainer}>
                    <VideoClipspotsContainer
                        openDetailPage={false}
                        video={video}
                        videoType={videoType}
                    />
                    {renderAdditionalButtons()}
                </div>
            </div>
            <VideoSlider
                videos={sliderVideos}
                videoType={sliderVideoType}
            />
        </>
    );
};

VideoDetailClipspotsContainer.propTypes = propTypes;

export default VideoDetailClipspotsContainer;
