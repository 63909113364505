//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import Url from '@helper/Url';

export default class VideoHelper {
    static isVideoElementPlaying(videoElement) {
        return (
            videoElement &&
            videoElement.currentTime > 0 &&
            !videoElement.paused &&
            !videoElement.ended &&
            videoElement.readyState > videoElement.HAVE_CURRENT_DATA
        );
    }

    static getVideoPath(video) {
        const videoFile = video?.videoFile?.path || video?.animatedAdFile?.path || video?.digitalAdFile?.path;

        if (!videoFile) {
            return null;
        }

        const videoFileUrl = Url.backendImage(videoFile);

        return videoFileUrl;
    }

    static getVideoPreviewImagePath(video) {
        const previewImage = video?.videoPreviewImage || video?.animatedAdPreviewImage || video?.digitalAdPreviewImage;

        if (!previewImage) {
            return null;
        }

        const previewImageUrl = Url.backendImage(previewImage?.path);

        return previewImageUrl;
    }

    static getVideoTextPath(video) {
        const videoText = video?.videoText?.path;

        if (!videoText) {
            return null;
        }

        const videoTextUrl = Url.backendImage(videoText);

        return videoTextUrl;
    }

    static findVideoInStack(videos, videoId) {
        return _.find(
            videos,
            {
                id: videoId,
            },
        );
    }
}
