//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useHistory } from 'react-router';

import PropTypes             from '@components/PropTypes';
import StatelessBackButton   from '@components/stateless/composed/BackButton';
import { getVideoTypeRoute } from '@constants/VideoType';

const propTypes = {
    backTextKey: PropTypes.string,
    videoType:   PropTypes.videoType,
};

const BackButton = ({
    backTextKey = 'backToOverview',
    videoType = null,
}) => {
    const history = useHistory();

    function onBackButtonClicked() {
        if (videoType) {
            history.push(getVideoTypeRoute(videoType));
        } else {
            history.goBack();
        }
    }

    return (
        <StatelessBackButton
            backTextKey={backTextKey}
            onBackButtonClicked={onBackButtonClicked}
        />
    );
};

BackButton.propTypes = propTypes;

export default BackButton;
