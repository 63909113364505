//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { connectRouter }      from 'connected-react-router';
import { combineReducers }    from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

import { AlertBoxReducer }              from '@slices/alertBox';
import { ContactRequestReducer }        from '@slices/contactRequest';
import { DigitalAdsReducer }            from '@slices/digitalAds';
import { LoadingReducer }               from '@slices/loading';
import { ShortclipsReducer }            from '@slices/shortclips';
import { StagingAuthenticationReducer } from '@slices/stagingAuthentication';
import { TutorialsReducer }             from '@slices/tutorials';
import { UserReducer }                  from '@slices/user';
import { VideoOverlayReducer }          from '@slices/videoOverlay';
import { VideosReducer }                from '@slices/videos';

export default (history) => withReduxStateSync(combineReducers({
    alertBox:              AlertBoxReducer,
    contactRequest:        ContactRequestReducer,
    loading:               LoadingReducer,
    shortclips:            ShortclipsReducer,
    digitalAds:            DigitalAdsReducer,
    stagingAuthentication: StagingAuthenticationReducer,
    tutorials:             TutorialsReducer,
    user:                  UserReducer,
    videoOverlay:          VideoOverlayReducer,
    videos:                VideosReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router: connectRouter(history),
}));
