//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes              from '@components/PropTypes';
import Button                 from '@stateless/atomic/Button';
import ClipspotsContainerSize from '@stateless/atomic/ClipspotsContainer/ClipspotsContainerSize';
import Icon                   from '@stateless/atomic/Icon';
import Spacer                 from '@stateless/atomic/Spacer';

import styles from './styles.module.scss';

const propTypes = PropTypes.clipspotsContainer;

const ClipspotsContainer = ({
    buttonProps = null,
    buttonSiblings = null,
    children = null,
    className = null,
    headline = null,
    image = null,
    videoId = null,
    onClick = null,
    onImageClick = null,
    size = ClipspotsContainerSize.default,
    subtitle = null,
    icon = null,
}) => {
    function renderHeadline() {
        if (headline) {
            return (
                <p className={styles.headline}>
                    {headline}
                </p>
            );
        }

        return null;
    }

    function renderSubtitle() {
        if (subtitle) {
            return (
                <p className={styles.subtitle}>
                    {subtitle}
                </p>
            );
        }

        return null;
    }

    function renderSpacer() {
        if (
            (
                headline ||
                subtitle
            ) &&
            size !== ClipspotsContainerSize.small
        ) {
            return (
                <Spacer height={10} />
            );
        }

        return null;
    }

    function renderImageIcon() {
        if (icon) {
            return (
                <Icon
                    className={styles.iconImage}
                    iconType={icon}
                />
            );
        }

        return null;
    }

    function onImageContainerClick(event) {
        if (onImageClick) {
            event.stopPropagation();
            onImageClick();
        }
    }

    function renderImage() {
        if (image) {
            return (
                <div
                    className={classNames(
                        styles.image,
                        {
                            [styles.clickable]: onImageClick,
                        },
                    )}
                    onClick={onImageContainerClick}
                >
                    <img
                        src={image}
                        alt={headline}
                    />
                    {renderImageIcon()}
                </div>

            );
        }

        return null;
    }

    function renderButtonContainer() {
        if (
            !buttonProps &&
            !buttonSiblings
        ) {
            return null;
        }

        function renderButton() {
            if (!buttonProps) {
                return null;
            }

            return (
                <Button {...buttonProps} />
            );
        }

        function renderButtonSiblings() {
            if (!buttonSiblings) {
                return null;
            }

            return buttonSiblings;
        }

        return (
            <div className={styles.button}>
                {renderButton()}
                {renderButtonSiblings()}
            </div>
        );
    }

    return (
        <>
            <div
                className={classNames(
                    className,
                    styles.clipspotsContainer,
                    styles[size],
                    {
                        [styles.clickable]: onClick,
                    },
                )}
                onClick={onClick}
                data-video-id={videoId}
            >
                {renderHeadline()}
                {renderSubtitle()}
                {renderSpacer()}
                {renderImage()}
                {children}
            </div>
            {renderButtonContainer()}
        </>
    );
};

ClipspotsContainer.propTypes = propTypes;

export default ClipspotsContainer;
